import imag1 from '../assets/imag1.png'
import imag2 from '../assets/imag2.png'
import imag3 from '../assets/imag3.png'
import imag4 from '../assets/imag4.png'
import imag5 from '../assets/imag5.png'
import imag6 from '../assets/imag6.png'
import imag7 from '../assets/imag7.png'
import imag8 from '../assets/imag8.png'
import imag9 from '../assets/imag9.png'
import imag10 from '../assets/imag10.png'
import imag11 from '../assets/imag11.png'
import imag12 from '../assets/imag12.png'
import imag13 from '../assets/imag13.png'
import imag14 from '../assets/imag14.png'

export const SliderProducts = [{
    name: 'Diamond',
    detail: 'Koh e Norr',
    price: '25',
    imag: imag1
},
{
    name: 'Diamond',
    detail: 'Koh e norr',
    price: '30',
    imag: imag2
},
{
    name: 'Diamond',
    detail: 'Koh e noor',
    price: '25',
    imag: imag3
}
]
export const ProductsData = [{
    name: 'Flawless',
    detail: 'Super Skin Care',
    price: '25',
    imag: imag1,
    type: 'flaw less',
},
{
    name: 'Flawless',
    detail: 'Super Skin Care',
    price: '30',
    imag: imag2,
    type: 'flaw less',
},
{
    name: 'VS1',
    detail: 'Super Skin Care',
    price: '25',
    imag: imag3,
    type: 'vs1',
},
{
    name: 'VS1',
    detail: 'Super Skin Care',
    price: '25',
    imag: imag4,
    type: 'vs1',
},
{
    name: 'VS2',
    detail: 'uper Skin Care',
    price: '30',
    imag: imag5,
    type: 'vs2',
},
{
    name: 'Flawless',
    detail: 'Super Skin Care',
    price: '25',
    imag: imag6,
    type: 'flaw less',
},
{
    name: "Flawless",
    details: 'Best Conditioner',
    price: '30',
    imag: imag7,
    type: 'flaw less'
}
,
{
    name: "VS2",
    details: 'Best Conditioner',
    price: '30',
    imag: imag8,
    type: 'vs2'
},
{
    name: "VS2",
    details: 'Best Conditioner',
    price: '30',
    imag: imag9,
    type: 'vs2'
},
{
    name: "VS1",
    details: 'Best Conditioner',
    price: '30',
    imag: imag10,
    type: 'vs1'
},
{
    name: "VS1",
    details: 'Nourish your skin',
    price: '12',
    imag: imag11,
    type: 'vs1'
},
{
    name: "Flawless",
    details: 'Nourish your skin',
    price: '12',
    imag: imag12,
    type: 'flaw less'
},
{
    name: "VS1",
    details: 'Nourish your skin',
    price: '12',
    imag: imag13,
    type: 'vs1'
},
{
    name: "VS2",
    details: 'Nourish your skin',
    price: '12',
    imag: imag14,
    type: 'vs2'
}

]