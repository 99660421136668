import React from 'react';
import { InboxIcon,
PhoneIcon,
LocationMarkerIcon,
LoginIcon,
UsersIcon,
LinkIcon,} from "@heroicons/react/outline";
import css from './Footer.module.css';
import Logo from '../../assets/logo3.png';



const Footer = () => {
    return (
        <div className={css.cFooterWrapper}>
            <hr/>

        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt="" />
                <span>Get Diamonds</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contatc Us</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon}/>
                        <span>ISSL-Uop </span>
                    </span>

                    <span className={css.pngLine}>
                        {" "}
                        <PhoneIcon className={css.icon} />
                        <a href="tel:352-386-4415">352-386-4415</a>
                    </span>

                    <span className={css.pngLine}>
            
                        <InboxIcon className={css.icon} />
                        <a href="mailto:Support@GetDiamond.com">Support@GetDiamond.com</a>
                    </span>

                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account</span>
                    <span className={css.pngLine}>
                        <LoginIcon className={css.icon}/>
                        Sign In
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                        <UsersIcon className={css.icon}/>
                        <a href="/about">
                         <p>About US</p>
                        </a>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon}/>
                        <p>Safety Privacy and Terms</p>
                    </span>
                </div>
            </div>

        </div>

        <div className={css.copyRight}>
            <span>Copyright 2023 by ISSL-UOP(NCCS)</span>
            <span>All Rights Reserved</span>
        </div>

        </div>
    )
}

export default Footer