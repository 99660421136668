import React from 'react'
import css from './Hero.module.css'
import HeroImg from '../../assets/hero1.png'
import {RiShoppingBagFill} from 'react-icons/ri'
import {BsArrowRight} from 'react-icons/bs'
import { motion}   from 'framer-motion'

const Hero = () => {

    const transition = {duration: 1, type: "spring" }

    return(
        <div className={css.container}>


            
            {/* left section*/}
            <div className={css.h_sides}>
                <span className={css.text1}>Shop the spectacular diamonds here</span>
                <div className={css.text2}>
                    <span>Trendy Collections</span>
                    <span>Vibrant gemstones to elegant diamonds. Shop captivating and contemporary diamond</span>
                </div>
            </div>
        
         {/* Mid section*/}
         <div className={css.wrapper}>
            <div className={css.blueCircle}></div>

        {/* Hero image */}
            <motion.img transition={transition} 
            initial={{bottom: "-3rem"}}
            whileInView={{bottom: "0rem"}}
            src={HeroImg} alt="" width={600} />

        {/* cart div */}
            <motion.div 
            ransition={transition} 
            initial={{right: "5%"}}
            whileInView={{right: "3%"}}
            className={css.cart2}>
                <RiShoppingBagFill />
            <div className={css.signup}>
                <span>Best Signup Offers</span>
                <div>
                    <BsArrowRight />
              </div>
             </div>    
           </motion.div>
         </div>

             {/* Right section*/}
            <div className={css.h_sides}>
             <div className={css.traffic}>
              <span>Millions of</span> 
              <span>Monthly traffic</span> 
             </div>    
             <div className={css.customers}>
                <span>100k</span>
                <span>Happy customers</span>
             </div>
            </div> 
        </div>

         );
};
export default Hero